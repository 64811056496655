/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useField } from 'formik';
import propTypes from 'prop-types';
import Error from '../Error/Error';

export const CheckboxWithoutBinding = ({
  containerClasses,
  checkboxClasses,
  labelClasses,
  children,
  isChecked,
  textClasses,
  ...props
}) => (
  <div className={containerClasses}>
    <label className={`${labelClasses} c-custom`}>
      <input
        className="c-custom__input"
        type="checkbox"
        checked={isChecked}
        {...props}
      />
      <span
        className={`${checkboxClasses} c-custom__custom-input c-custom__check`}
      />
      <span className={`c-custom__text ${textClasses}`}>{children}</span>
    </label>
  </div>
);

const Checkbox = ({
  containerClasses,
  checkboxClasses,
  labelClasses,
  textClasses,
  children,
  // customChangeHandler,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div className={containerClasses}>
      <label className={`${labelClasses} c-custom ${disabled ? 'disabled' : ''}`}>
        <input
          disabled={disabled}
          {...field}
          {...props}
          className="c-custom__input"
          type="checkbox"
          // onChange={(event) => {
          //   if (customChangeHandler) {
          //     field.onChange(event);
          //     customChangeHandler(formikValues);
          //   } else {
          //     field.onChange(event);
          //   }
          // }}
          checked={meta.value}
        />
        <span
          className={`${checkboxClasses} c-custom__custom-input c-custom__check`}
        />
        <span className={`c-custom__text ${textClasses}`}>{children}</span>
      </label>
      {meta.touched && <Error error={meta.error} />}
    </div>
  );
};

Checkbox.propTypes = {
  labelClasses: propTypes.string,
  containerClasses: propTypes.string,
  checkboxClasses: propTypes.string,
  disabled: propTypes.bool,
};

Checkbox.defaultProps = {
  labelClasses: '',
  containerClasses: '',
  checkboxClasses: '',
  disabled: false,
};

export default Checkbox;
