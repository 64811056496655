import React from 'react';
import { Link } from 'gatsby';
import classes from './NavigationItem.module.scss';

const navigationItem = ({
  link, children, onLinkClick, isExternal = false,
}) => (
  <li className={classes.NavigationItem}>
    {!isExternal ? (
      <Link
        partiallyActive
        onClick={onLinkClick}
        to={link}
        activeClassName={classes.active}
      >
        {children}
      </Link>
    )
      : (
        <a href={link} target="_blank" rel="noreferrer">
          {children}
        </a>
      )}
  </li>
);

export default navigationItem;
