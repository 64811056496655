import { useEffect } from 'react';
import { BODY_BACKDROPED } from '../constans/css-classes';

export const useBodyBackDrop = (breakPointIsReached) => {
  useEffect(() => {
    if (breakPointIsReached) {
      document.body.classList.remove(BODY_BACKDROPED);
    }
  }, [breakPointIsReached]);
};
