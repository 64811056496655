import { noop } from "../helpers/helper-methods";

const SessionService = () => {
    const isBrowser = typeof window !== 'undefined' && window.localStorage;
    const version = 'v1';
  
    if (isBrowser) {
      const keyGen = (key) => `koral:${key}__${version}`;
  
      const remove = (key) => sessionStorage.removeItem(keyGen(key));
  
      const clear = () => {
        sessionStorage.clear();
      };
  
      const set = (key, value) => {
        sessionStorage.setItem(keyGen(key), JSON.stringify(value));
      };
    
      const get = (key, defaultValue = {}) => {
        const res = sessionStorage.getItem(keyGen(key));
  
        try {
          return res ? JSON.parse(res) : defaultValue;
        } catch {
          return defaultValue;
        }
      };
  
      return {
        remove,
        clear,
        get,
        set,
      };
    }

    return {
        remove: noop,
        clear: noop,
        get: noop,
        set: noop,
      };
  };
  
  export default SessionService;