import React, { useState } from 'react';

import { noop } from '../../helpers/helper-methods';
import IconSearch from '../../assets/img/icons/icon-lens.svg';
import IconClose from '../../assets/img/icons/close.svg';

import styles from './index.module.scss';

const SearchInput = ({
  onChange,
  placeholder,
  value,
  containerStyles,
  inputStyles,
  searchBtnStyles,
  closeBtnStyles,
  onKeyPress = noop,
  isDestinationTree = false,
  isSimpleSearch = false,
}) => {
  const [isVisible, setVisible] = useState(false);
  const toggleVisibility = () => setVisible(!isVisible);

  return (
    <div className={`flex ${isDestinationTree ? styles.wrapperDestination : styles.wrapper} ${containerStyles}`}>
      <input
        placeholder={placeholder}
        onChange={onChange}
        className={`${isDestinationTree ? styles.searchDestination : styles.search} ${!isVisible && !isDestinationTree ? !isSimpleSearch ? styles.invisible : '' : ''} ${inputStyles}`}
        value={value}
        onKeyPress={onKeyPress}
      />
      <button
        type="button"
        onClick={(e) => {
          onChange(e);
          setVisible(false);
        }}
        className={`${isDestinationTree ? styles.closeDestination : styles.close} ${closeBtnStyles} ${value.length === 0 ? styles.invisible : ''}`}
      >
        <IconClose />
      </button>
      <button
        type="button"
        onClick={toggleVisibility}
        className={`${styles.button} ${searchBtnStyles} ${value.length !== 0 ? styles.invisible : ''}`}
      >
        <IconSearch />
      </button>
    </div>
  );
};

SearchInput.defaultProps = {
  containerStyles: '',
  inputStyles: '',
  searchBtnStyles: '',
};

export default SearchInput;
