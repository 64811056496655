import { Link } from 'gatsby';
import React, { useState } from 'react';

import IconClose from '../../../assets/img/icons/close.svg';
import { CUSTOMER_NAVBAR } from '../../../constans/links';
import StatusModal from '../../StatusModal';

import styles from './ClientMenu.module.scss';

function ClientMenu({ isShown, onClose }) {
  const [isStatusShown, setIsStatusShown] = useState(false);

  return (
    <>
      <StatusModal isOpen={isStatusShown} toggle={() => setIsStatusShown((state) => !state)} />
      <div
        className={`${styles.wrapper} ${isShown && styles.active}`}
      >
        <div className={styles.header}>
          <div className="flex flex-v-center flex-between">
            <div className="flex flex-v-center ml-auto">
              <button
                type="button"
                className={`btn flex flex-v-center ${styles.close}`}
                onClick={onClose}
              >
                Close
                <IconClose />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          {/*
          <button
            className={styles.button}
            type="button"
            onClick={() => setIsStatusShown(true)}
          >
            Status
          </button>
          */}
          {CUSTOMER_NAVBAR.map(({ href, title }, index) => (
            <Link
              key={index}
              to={href}
              onClick={onClose}
              partiallyActive
              activeClassName={styles.active}
              className={styles.link}
            >
              {title}
            </Link>
          ))}
        </div>
      </div>
      {isShown && <div className={styles.backdrop} onClick={onClose} />}
    </>
  );
}
export default ClientMenu;
