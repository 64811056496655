import React, { useState } from 'react';

import IconClose from '../../../../../assets/img/icons/close.svg';

import styles from './InputSearchMobile.module.scss';

const InputSearchMobile = ({
  onChange,
  placeholder,
  value,
  containerStyles,
  inputStyles,
  closeBtnStyles,
  onKeyPress = () => {},
  onFocus = () => {},
}) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <div className={`flex m-0 ${styles.wrapper} ${containerStyles}`}>
      <input
        placeholder={placeholder}
        onChange={onChange}
        className={`${styles.search} ${!isVisible ? styles.invisible : ''} ${inputStyles}`}
        value={value}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
      />
      <button
        type="button"
        onClick={(e) => {
          onChange(e);
          setVisible(false);
        }}
        className={`${styles.close} ${closeBtnStyles} ${!isVisible ? styles.invisible : ''}`}
      >
        <IconClose />
      </button>
    </div>
  );
};

InputSearchMobile.defaultProps = {
  containerStyles: '',
  inputStyles: '',
  searchBtnStyles: '',
};

export default InputSearchMobile;
