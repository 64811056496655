import { noop } from '../helpers/helper-methods';

const CookiesService = () => {
    const version = 'v1';
    const isBrowser = typeof window !== 'undefined'
        && typeof document !== 'undefined'
        && document.cookie;

    if (isBrowser) {
        const keyGen = (key) => `koral:${key}__${version}`;

        const setCookie = (name, value, days) => {
            let expires = "";
            if (days) {
                const date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = keyGen(name) + "=" + (value || "") + expires + "; path=/";
        }

        const getCookie = (name) => {
            const nameEQ = keyGen(name) + "=";
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        }

        const eraseCookie = (name) => {
            document.cookie = keyGen(name) + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }

        return {
            setCookie,
            getCookie,
            eraseCookie
        }
    }

    return {
        setCookie: noop,
        getCookie: noop,
        eraseCookie: noop
    }
}

export default CookiesService;