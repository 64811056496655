/* eslint-disable max-len */
import React from 'react';
import { Link } from 'gatsby';
// import classes from './TermsOfService.module.scss';
import { CONTACTS_LINK } from '../../../constans/links';

const TemsOfService = () => (
  <div className="infoTextContainer">
    <div className="infoTextTitle t-700">Terms of Service</div>
    <div className="infoTextInner">
      <p>1. Introduction</p>
      <div className="ml-15">
        <p>
          These Terms of Service, as may be amended from time to time, apply to
          all our Services. If you are a Customer, by accessing or using the
          Platform – including by signing-up and creating an account, you agree to
          comply and be bound by these Terms.
        </p>
        <p>
          For Sellers, these would be documented into a separate legal agreement.
        </p>
        <p>
          To access and use the Platform, you must be at least 18-year-old and not
          have been previously prohibited by Koralgo to use the Platform.
        </p>
      </div>
      <p>2. Definitions</p>
      <div className="infoTextNested">
        <p>
          <span className="t-700">“Customer”</span>
          {' '}
          means a user of the Koralgo
          Platform looking for information or to avail the Services, including
          purchase or booking of one or several Offers from a Seller.
          {' '}
        </p>
        <p>
          <span className="t-700">“Koralgo”</span>
          ,
          {' '}
          <span className="t-700">“we”</span>
          ,
          {' '}
          <span className="t-700">“us”</span>
          ,
          {' '}
          <span className="t-700">“our”</span>
          , means Koralgo Pte Ltd, a company
          with limited liability incorporated under the laws of Singapore and
          having its registered address at 51 Goldhill Plaza, #07-10/11,
          Singapore.
        </p>
        <p>
          <span className="t-700">“Koreval”</span>
          {' '}
          means a professional reviews
          and ratings provider that meets the Platform’s qualification and
          process requirements. Koreval is currently a business division of
          Koralgo.
        </p>
        <p>
          <span className="t-700">“Offer”</span>
          {' '}
          means a product, ticket,
          service or experience marketed by a Seller on the Platform.
        </p>
        <p>
          <span className="t-700">“Offer T&C”</span>
          {' '}
          means the terms and
          conditions of a Seller’s Offer, including the fine print.
        </p>
        <p>
          <span className="t-700">“Payment Processor”</span>
          {' '}
          means the party
          facilitating the transaction between the various parties using a
          payment gateway.
        </p>
        <p>
          <span className="t-700">“Payment Services”</span>
          {' '}
          means any and all
          payment services provided in connection with the use of the Platform
          and the Services. The payment processing of the Platform is carried
          out by a third-party Payment Processor.
        </p>
        <p>
          <span className="t-700">“PV”</span>
          {' '}
          or
          {' '}
          <span className="t-700">“Personal Value”</span>
          {' '}
          means the value
          computed by a proprietary algorithm taking into account a Customer
          profile and preferences, his/her search parameters and the rating data
          of Offers. PVs are derived to produce Personal Value Ranking (PVR). PV
          or Personal Value and PVR or Personal Value Ranking are concepts in
          the process of being patented and form part of Koralgo intellectual
          property.
        </p>
        <p>
          <span className="t-700">“Platform”</span>
          {' '}
          means the Koralgo website or
          application, whether it is accessed from a desktop, laptop, mobile,
          tablet or other smart device.
        </p>
        <p>
          <span className="t-700">“Review”</span>
          {' '}
          and
          {' '}
          <span className="t-700">“Rating”</span>
          {' '}
          means comments written by a
          ratings analyst about a Seller, its assets (such as a property) and
          Offers and published on the Platform and the rating data supplied by
          the rating provider that is used by the Platform algorithm to compute
          PV (Personal Value).
        </p>
        <p>
          <span className="t-700">“Services”</span>
          {' '}
          means all the
          functionalities and services made available to a Customer through the
          Platform, including but not limited to:
        </p>
        <div className="ml-15 mb-1em">
          <div>
            • Providing information, Reviews and Ratings content about Sellers and their Offers
          </div>
          <div>
            • Purchase and reservation services in relation with Offers
          </div>
          <div>
            • Setting up one or several profiles per Customer account
          </div>
          <div>
            • Searching for Offers according to search parameters provided by the Customer
          </div>
          <div>
            • Computing Personal Value scores and ranking offers accordingly
          </div>
          <div>
            • Marketing services providing Customers with the option to set their preferences for receiving promotional offers from Sellers
          </div>
          <div>
            • Receiving booking or purchase confirmations and accessing booking and purchase history
          </div>
          <div>
            • The Payment Services
          </div>
        </div>
        <p>
          <span className="t-700">“Seller”</span>
          {' '}
          means a producer, merchant or vendor that
          markets its product or service to Customers through the Platform.
        </p>
        <p>
          <span className="t-700">“Terms”</span>
          {' '}
          means these Terms of Service agreement.
        </p>
      </div>
      <div className="mb-1em">
        <p>
          3. Modification of Terms
        </p>
        <div className="ml-15">
          Koralgo reserves the right to modify these Terms and, when we do so, we will provide you with advance notice of the changes by email and the revised Terms will be posted on the Platform. If you continue to access or use the Platform, you are effectively agreeing to the revised Terms.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          4. Scope and nature of Services
        </p>
        <div className="ml-15">
          The Platform is an online marketplace that enables Customers to book or purchase Offers from Sellers such as hotel stays. The Offers are not produced, controlled, owned, or sold by Koralgo so the contract is between Customers and Sellers and the latter are responsible party for the description, content, delivery, and performance of the Offers.
          We use the concept of Personal Value to rank Offers and facilitate Customers’ selection process.
          The Platform displays Reviews and Ratings to provide information in relation with Sellers assets (such as a hotel property) and Offers. We also provide marketing and booking services as well as Payment Services to Customers.
          Finally, our Services to Customers are provided for personal and non-commercial use which precludes you from re-selling, displaying or reproducing any content or information for commercial purpose.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          5. Account Registration
        </p>
        <div className="ml-15">
          Certain features and Services of the Platform can only be made available for registered users. The information provided during the account creation must be truthful and accurate and must remain up to date.
          You are responsible for keeping your account credentials secured and confidential and you cannot assign your account to somebody else without the express consent of Koralgo.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          6. Termination and Prohibited activities
        </p>
        <div className="ml-15">
          You may terminate this agreement by give us notice through the contact
          form (as per Contact clause). Your existing bookings will be cancelled
          with any refunds being subject to the particular terms and conditions
          of the Offers you booked or purchased.
          Koralgo may terminate your account with immediate effect and stop
          providing you access to the Platform, including through suspension
          or deletion of your account, if you have materially breached your
          obligations or the rules under these Terms or applicable laws and
          regulations. Your existing bookings may be cancelled with any refunds
          being subject to the particular terms and conditions of the Offers
          you booked or purchased. In this scenario, you are also prohibited
          from creating a new user account, unless we have subsequently
          explicitly authorized you to do so.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          7. Limitation on liability and warranty disclaimer
        </p>
        <div className="ml-15">
          You acknowledge and agree that, to the extent permitted by law, the Platform only provide you with the Services and is not responsible for the description, content, delivery, and performance of the Offers. You therefore also acknowledge that we cannot be held responsible for any physical injury, disability, death or illness incurred or sustained in relation with the Offers booked or purchased through the Platform.
          Subject to these Terms, we shall only be liable for direct damages actually suffered by you, up to the value of the Offer purchased or booked, in cases of obvious error or shortcoming arising during the performance of the Services.
          We are also not responsible for the validity, accuracy and truthfulness of the content displayed on the Platform or through links by Sellers, including that of the Offers they market.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          8. Booking edits, cancellations, refunds and Customer support
        </p>
        <div className="ml-15">
          As mentioned in the above “Scope and nature of Services” clause, the purchasing of booking of Offers is a contract between Customer and Seller. By using the Platform and performing such a transaction, you – the Customer – agree that it is your responsibility to read and understand the Offer T&C and be bound by them.
          Your ability to edit a booking and the potential fees associated to it will be documented in the Offer T&C.
          The refund policy and amounts in case of cancellation, no-show, late arrival or late departure will also be documented in the Offer T&C.
          This clause and the Offer T&C apply to prepaid, partially paid and unpaid / not yet paid bookings and purchases.
          The Platform’s role is limited to enabling the edit or cancellation and the initial stage of communication between a Customer and Seller in those instances, if and when required.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          9. Taxes
        </p>
        <div className="ml-15">
          All Offers price on the Platform are inclusive of Sales Tax or VAT (as applicable) and all other taxes or fees, unless otherwise stated. Some of those taxes or fees may be charged by the Seller even in the case of a cancellation or no-show – please refer to the Offer T&C for details. Where some taxes are not part of the Offer price and are payable directly to the Seller, these will be stated in the Offer T&C and displayed prior to you purchasing or booking the Offer.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          10. Transaction currency and price
        </p>
        <div className="ml-15">
          The currency of payment is stated and displayed in the Offer.
          The amount converted in another currency may be displayed for information only and cannot be relied upon by the Customer.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          11. Reviews and Ratings
        </p>
        <div className="ml-15">
          The Reviews and Ratings are provided to the Platform for publishing and computing the PV. They are carried out to high professional standards and as objectively as possible without perceived conflict of interest. However, the Platform cannot ensure the accuracy of the reviews, ratings and rating data, in particular as there will always be an element of subjectivity involved.
          You therefore acknowledge that the reviews and ratings are provided to the best of the rating analyst’s knowledge and that the Platform cannot be held responsible for any inaccuracy in the Ratings and Reviews or for any loss perceived or sustained in relation with the booking or purchase of Offers.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          12. Privacy and Cookies policies
        </p>
        <div className="ml-15">
          We have separate, dedicated, Privacy Policy and Cookies Policy.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          13. Intellectual Property
        </p>
        <div className="ml-15">
          Unless stated otherwise, the content of the Platform is owned by Koralgo or the Sellers and cannot be copied, marketed or used without our written permission. To do so would constitute a material infringement of our intellectual property rights.
          Koralgo does not owe a user any credit if it acts on that user’s improvement suggestion or feedback.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          14. Governing law, jurisdiction and dispute resolution
        </p>
        <div className="ml-15">
          This Agreement is governed by and will be construed in accordance with the laws of Singapore and you agree to submit to the non-exclusive jurisdiction of the Singapore Courts
          Any dispute arising out of or in connection with this contract shall be referred to and finally resolved by the Singapore International Arbitration Centre in accordance with its Arbitration Rules for the time being in force, which rules are deemed to be incorporated by reference in this clause.
        </div>
      </div>
      <div className="mb-1em">
        <p>
          15. Contact information
        </p>
        <div className="ml-15">
          If you have any query regrading this Agreement, please contact us
          through the
          {' '}
          <Link to={`${CONTACTS_LINK}`} className="inlined-link">
            Contact Form
          </Link>
          {' '}
          and select the following topic: “Terms, Privacy and Cookies”.
        </div>
      </div>
    </div>
  </div>
);

export default TemsOfService;
