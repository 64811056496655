import React from 'react';
import LandingInfoWrapper from '../Landing-info/LandingInfoWrapper/LandingInfoWrapper';
import classes from './Footer.module.scss';

const Footer = () => (
  <footer className={classes.footer}>
    <div
      className={`${classes.container} landing-container flex flex-v-center flex-between`}
    >
      <div className={classes.rights}>
        ©
        {' '}
        {new Date().getFullYear()}
        {' '}
        Koralgo. All rights reserved.
      </div>
      <LandingInfoWrapper btnsWrapper={classes.links} btnsWrapperSocial={classes.social} />
    </div>
  </footer>
);

export default Footer;
