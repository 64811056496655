import { isBrowser } from '../helpers/helper-methods';

export function useLockBodyScroll() {
  const { body } = isBrowser() ? document : {};

  const disable = () => body?.classList.add('lockScroll');

  const enable = () => body?.classList.remove('lockScroll');

  return { disable, enable };
}
