import React, { useState } from 'react';
import propTypes from 'prop-types';
import { SIGN_LINKS } from '../../../constans/links';
import classes from './SignNav.module.scss';
import { BODY_BACKDROPED } from '../../../constans/css-classes';
import SignUpModal from '../../AutorizationModal/SignUpModal';
import { isAuthGuard } from '../../../guards/isAuthGuard';

function SignNav({ containerClasses }) {
  const isAuth = isAuthGuard();
  const [isModalShown, setIsModalShown] = useState(false);

  const onClickHandler = () => {
    document.body.classList.remove(BODY_BACKDROPED);
    setIsModalShown(!isModalShown);
  };

  return (
    <div className={`${containerClasses} ${classes.signNav}`}>
      {SIGN_LINKS.map((signLink, index) => (
        <div
          onClick={onClickHandler}
          key={index}
          className={`${classes.text} t-500 btn btn_primary-link-light`}
        >
          {signLink.title}
        </div>
      ))}
      <SignUpModal
        isShown={!isAuth && isModalShown}
        toggleModal={() => setIsModalShown()}
      />
    </div>
  );
}

SignNav.propTypes = {
  containerClasses: propTypes.string,
};

SignNav.defaultProps = {
  containerClasses: '',
};

export default SignNav;
