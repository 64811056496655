import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash.get';

import useDebounce from '../../../../hooks/useDebounce';
import { ENTER_KEY } from '../../../../constans/validation';
import { FIRST_SUGGESTIONS_INDEX, TOOLBAR_SEARCH_ENTITY_TYPES } from '../../../../constans/search';
import InputWrapper from '../../../UI/InputWrapper/InputWrapper';
import InputSearchMobile from './InputSearchMobile/InputSearchMobile';
import SearchInput from '../../../Search';

import styles from './SearchInputAutocomplete.module.scss';
import {
  filterDestinationsAllValues,
  getDestinationType,
  getDestinationValue,
  sortDestinationByKey,
} from '../../../DestinationTree/helper';
import IconMarkerCountry from '../../../../assets/img/icons/ic-marker_country.svg';
import IconMarkerRegion from '../../../../assets/img/icons/ic-marker_region.svg';
import IconMarkerCity from '../../../../assets/img/icons/ic-marker_city.svg';
import IconMarkerDistrict from '../../../../assets/img/icons/ic-marker_district.svg';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { DESTINATION_TYPE } from '../../../DestinationTree/const';

export const DESTINATION_ICON = {
  country: <IconMarkerCountry />,
  region: <IconMarkerRegion />,
  city: <IconMarkerCity />,
  district: <IconMarkerDistrict />,
};

function AutocompleteItem({
  onClick,
  prefixIcon,
  displayedValue,
  dropdownItemStyles,
  dropdownItemTextStyles,
}) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${styles.suggestionsOption} ${dropdownItemStyles}`}
    >
      {prefixIcon}
      <div className={`${dropdownItemTextStyles} text-truncate`}>
        {displayedValue}
      </div>
    </button>
  );
}

function NoResults({ text, textStyle }) {
  return (
    <div className={styles.emptySearchContainer}>
      <div className={`${styles.emptySearchContainerContent} ${textStyle}`}>{text}</div>
    </div>
  );
}

function SearchInputAutocomplete({
  children,
  placeholder,
  wrapperClassName,
  onChange,
  onFocus,
  onSelect,
  prefixActivityIcon,
  prefixPropertyIcon,
  setFieldValue,
  name,
  dropdownStyles,
  dropdownItemStyles,
  dropdownItemTextStyles,
  inputInitialValue,
  errorElement,
  flipped,
  isMobile,
  getResultValue = (value) => (value),
  submitForm = (value) => (value),
  isDestinationTree = false,
  selectedName,
}) {
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState(inputInitialValue);
  const [isShown, setIsShown] = useState(false);
  const wrapperRef = useRef(null);
  const debouncedQuery = useDebounce(inputValue, 300);

  useEffect(() => {
    setInputValue(inputInitialValue);
  }, [inputInitialValue]);

  const handleSelect = (value) => {
    if (onSelect) {
      onSelect(value);
    }

    setInputValue(value);

    setIsShown(false);
  };

  const handleClickButton = (event) => {
    const { target } = event;

    handleSelect(getResultValue(searchResults[FIRST_SUGGESTIONS_INDEX]));

    if (target) {
      target.blur();
    }
  };

  const handleEnterKeyPress = (event) => {
    const { target, key } = event;

    if (key === ENTER_KEY && searchResults?.length) {
      event.preventDefault();

      handleSelect(getResultValue(searchResults[FIRST_SUGGESTIONS_INDEX]));

      if (target) {
        target.blur();
      }
    }
  };

  const handleSearch = (searchValue) => {
    if (searchValue?.length) {
      onChange(searchValue)
        .then(({ data }) => {
          setIsShown(true);
          if (isDestinationTree) {
            const countries = [];
            const regions = [];
            const cities = [];
            const districts = [];

            filterDestinationsAllValues(data).map((item) => {
              const transformedItem = {
                ...item,
              };
              const region = get(item, 'region');
              const city = get(item, 'region.city');
              const district = get(item, 'region.city.district');

              transformedItem.title = getDestinationValue('name', item, region, city, district);
              transformedItem.id = getDestinationValue('id', item, region, city, district);
              transformedItem.type = getDestinationType(district, city, region);

              const DESTINATIONS = {
                [DESTINATION_TYPE.district]: districts,
                [DESTINATION_TYPE.city]: cities,
                [DESTINATION_TYPE.region]: regions,
                [DESTINATION_TYPE.country]: countries,
              };

              DESTINATIONS[transformedItem.type].push(transformedItem);

              return transformedItem;
            });

            // ▪ Rule #1: Priority to hierarchy, i.e. shorter geocodes (Countries followed by Regions followed by Cities and, last, Districts)
            // ▪ Rule #2: Alphabetical sorting
            const suggestions = (sortDestinationByKey(countries)).concat(
              sortDestinationByKey(regions),
              sortDestinationByKey(cities),
              sortDestinationByKey(districts),
            ).filter(Boolean);

            if (selectedName === debouncedQuery) {
              setIsShown(false);
              setSearchResults([]);
            } else {
              setSearchResults(suggestions);
            }
          } else {
            setSearchResults(data);
          }
          submitForm();
        });
    } else {
      setIsShown(false);
    }
  };

  const handleChange = ({ target }) => {
    const { value } = target;

    setInputValue(value);
  };

  const handleFocus = (event) => {
    const { target: { value } } = event;
    if (onFocus) {
      onFocus();
    }

    setIsShown(false);
    handleSearch(value);
  };

  useEffect(() => {
    handleSearch(debouncedQuery);
    setFieldValue(name, debouncedQuery);
  }, [debouncedQuery]);

  useOutsideClick(wrapperRef, () => {
    if (isShown) setIsShown(false);
  });

  return (
    <InputWrapper ref={wrapperRef} containerClasses={wrapperClassName}>
      {!isMobile ? (
        <SearchInput
          containerStyles={styles.search}
          searchBtnStyles={styles.searchButton}
          closeBtnStyles={styles.closeBtn}
          placeholder={placeholder}
          onChange={(event) => handleChange({ target: event.currentTarget })}
          onClickButton={handleClickButton}
          onKeyPress={handleEnterKeyPress}
          onFocus={handleFocus}
          value={inputValue}
          type="text"
          isDestinationTree={isDestinationTree}
        />
      ) : (
        <InputSearchMobile
          containerStyles={styles.search}
          searchBtnStyles={styles.searchButton}
          closeBtnStyles={styles.closeBtn}
          placeholder={placeholder}
          onChange={(event) => handleChange({ target: event.currentTarget })}
          onClickButton={handleClickButton}
          onKeyPress={handleEnterKeyPress}
          onFocus={handleFocus}
          value={inputValue}
          type="text"
        />
      )}
      {children}
      {errorElement}
      <div
        className={`
          ${dropdownStyles}
          ${styles.transition}
          ${isDestinationTree ? styles.suggestionsDestination : styles.suggestions}
          ${flipped && styles.toTop}
          ${searchResults && isShown && `${isDestinationTree ? styles.suggestionsDestinationShown : styles.suggestionsShown} filled`}
        `}
      >
        {
          (searchResults.length)
            ? searchResults.map((suggestion, index) => (
              <AutocompleteItem
                dropdownItemStyles={dropdownItemStyles}
                dropdownItemTextStyles={dropdownItemTextStyles}
                key={suggestion?.id || `${suggestion}-${index}`}
                prefixIcon={
                !isDestinationTree
                  ? suggestion.type === TOOLBAR_SEARCH_ENTITY_TYPES.activity
                    ? prefixActivityIcon
                    : prefixPropertyIcon
                  : DESTINATION_ICON[suggestion.type]
                }
                displayedValue={getResultValue(suggestion)}
                onClick={() => handleSelect(getResultValue(suggestion))}
              />
            ))
            : <NoResults text="No results found" textStyle={dropdownItemTextStyles} />
      }
      </div>
    </InputWrapper>
  );
}

SearchInputAutocomplete.defaultProps = {
  dropdownStyles: '',
  dropdownItemStyles: '',
  dropdownItemTextStyles: '',
  inputInitialValue: '',
  errorElement: null,
};

export default SearchInputAutocomplete;
