/* eslint-disable max-len */
import React from 'react';
import { Link } from 'gatsby';

import { CONTACTS_LINK } from '../../../constans/links';

import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy = ({ showInnerTermsModal, showInnerCookiesModal }) => (
  <div className="infoTextContainer">
    <div className="infoTextTitle t-700">Privacy Policy</div>
    <div className="infoTextInner">
      <p>A) Definitions and why we have a privacy policy</p>
      <p>
        A privacy policy is a statement or a legal document
        that disclose the ways a company collects, uses, processes,
        handles and discloses a customer or client’s data.
      </p>
      <p>
        The need to have a privacy policy originates from
        regulatory requirements
        (applicable according to the location of data handling
        and processing but also your location)
        as well as from the use of third-party services that
        expressly require us to have one.
        The last reason is simply that we wish to be transparent
        with you, no questionable practice here.
      </p>
      <p>
        Our policy is based on the EU General Data Protection Regulation
        (GDPR), a regulation set forth for people
        located in the EU. It is widely considered to offer the most
        comprehensive user right protection framework, which is why we
        are applying those principles to all our users and supplementing
        them with additional local requirements if needed.
      </p>
      <p>
        The following privacy policy covers the Services
        provided by Koralgo, as defined in our
        {' '}
        <a className="inlined-link" href='/terms-of-service'>
          Terms of Service
        </a>
        .
      </p>
      <p>
        When this policy mentions “Koralgo”, “we”, “our”, “us”, it refers to the
        Koralgo company responsible for your information under this policy. The
        definition of all defined terms can be found in our
        {' '}
        <a className="inlined-link" href='/terms-of-service'>
          Terms of Service
        </a>
        {' '}
        agreement.
      </p>
      <p>
        B) Collection of information
      </p>
      <p>
        Koralgo collects information in two different ways.
      </p>
      <div className="ml-15 mb-1em">
        <p>(i) Information you provide to us about you and others</p>
        <p>
          Koralgo requests the following type of personal
          information when using the Platform so
          that it can provide the Services:
        </p>
        <div className="ml-15 mb-1em">
          <div>
            • Account information when you sign up such as
            your name, contact details, gender or date of birth
          </div>
          <div>
            • Personal information relating to other guests
            or customers when completing a purchase or booking
            such as name, delivery address or date of birth
          </div>
          <div>
            • Identity verification information such as Passport or ID details
          </div>
          <div>
            • Payment information such as credit card details
            to be able to process payments and complete bookings or purchase
          </div>
          <div>
            • Content of communications with us and between Customers and Sellers
          </div>
          <div>
            • For Sellers, details relating to the assets,
            products and offers marketed on the Platform,
            including data such as price and inventory
          </div>
        </div>
      </div>
      <p>
        The legal basis for doing so is that the collection and processing of the above type of data
        is necessary to enable Koralgo to provide the Services, including
        the administration of bookings and processing of payments.
      </p>
      <div className="ml-15 mb-1em">
        <p>
          (ii) Information we collect automatically
        </p>
        <p>
          When you navigate on and use the Platform, we collect
          various types of additional information:
        </p>
        <div className="ml-15 mb-1em">
          <div>
            • Geo-location information through
            data provided by your mobile device
            GPS or IP address which enables us,
            for instance, to show your location and that of properties on a map
          </div>
          <div>
            • Log data and usage information such
            as the pages you visited or bookings
            you have made, access time and duration,
            crash data as well as cookie data
          </div>
          <div>
            • Payment transaction information such as
            amount, date and time, payment method and
            instrument for record keeping, fraud
            prevention and, generally, provide you with the Payment Services
          </div>
          <div>
            • Device Information such as hardware and software information
          </div>
          <div>
            • Cookies and similar technologies as per our
            {' '}
            <a href='/cookies-policy' className="inlined-link">
              Cookies Policy
            </a>
          </div>
        </div>
      </div>
      <p>
        The legal basis for doing so is to optimize the quality,
        reliability and overall user experience of the Platform
        as well as ensure the provision of the Services.
      </p>
      <div className="mb-1em">
        <div className="mb-1em">
          <p>
            C) Usage, processing and sharing of information
          </p>
          <div>
            Generally speaking, we use, process and share
            your personal information for security and
            authentication purposes, to enable us to provide
            the Services and improve their relevance to you as
            well as to communicate with you or comply with our
            regulatory requirements.
          </div>
        </div>
        <div className="ml-15">
          <div className="mb-1em">
            <div>
              (i) Provide and improve our Services
            </div>
            <div>
              We use and process
              the information so you can browse and navigate on our platform in
              a personalized and efficient matter and we can provide the
              Services. This includes sharing of information with Sellers
              to complete bookings or purchases.
            </div>
          </div>
          <div className="mb-1em">
            <div>
              (ii) Security and authentication
            </div>
            <div>
              We need to be able to identify and authenticate you in certain
              situations such as when you log-in on the Platform or detect
              and prevent fraud – for this, we need to access and use your
              personal information.
            </div>
          </div>
          <div className="mb-1em">
            <div>
              (iii) Access and use the Payment Services [including data sharing]
            </div>
            <div>
              We also share your personal information with our Payment
              Processor at the time a transaction is being processed.
              It is a leading player in that space who also has its
              own comprehensive privacy policy.
            </div>
          </div>
          <div className="mb-1em">
            <div>
              (iv) Improve our profiling
            </div>
            <div>
              One of the strengths of Koralgo is the ability to
              provide you with what our algorithm computes as the
              best offers based on your preferences and search parameters.
              To achieve this, using and processing personal information is
              key to improve our profiling, whether it is based on what you
              tell us or through other methods such as A/B testing or
              calibration through meta-data. In the latter case we use
              anonymized meta-data.
            </div>
          </div>
          <div className="mb-1em">
            <div>
              (v) Provide analytics services to Sellers
            </div>
            <div>
              Using anonymized personal data and meta-data relating to
              profiles, we can also provide ground-breaking analytic
              services to Sellers, so their offers and pricing strategy
              is optimized taking into account the potential customer
              base and competitive landscape.
            </div>
          </div>
          <div className="mb-1em">
            <div>
              (vi) Provide marketing services to Consumers and Sellers
            </div>
            <div>
              Sharing anonymized personal data and meta-data with
              Sellers also enables us to provide marketing services
              to both Customers and Sellers that are more relevant
              and targeted, respectively.
            </div>
          </div>
          <div className="mb-1em">
            <div>
              (vii) Communicate with you
            </div>
            <div>
              Being able to respond to
              your query, send you a booking or
              purchase confirmation or a reminder
              also requires us to use your personal information.
            </div>
          </div>
          <div className="mb-1em">
            <div>
              (viii) Sharing with Services Providers and Corporate Affiliates
            </div>
            <div>
              We use Service Providers globally who may need to access
              personal information. They are contractually bound to
              protect such information and restrict their use to the
              task they need to perform.
              We may also share personal information to provide the
              Services within the Koralgo group of companies.
            </div>
          </div>
          <div className="mb-1em">
            <div>
              (ix) Regulatory requirements and business transfers
            </div>
            <div>
              Compliance with regulatory requirements in various
              jurisdictions may also require us to share personal information.
              Finally, if Koralgo is involved in a merger, sale,
              reorganization or bankruptcy / insolvency, we may
              sell or share some assets as part of that transaction.
              If your personal information is part of those assets,
              we will notify you before the event takes place and
              your personal information becomes subject to a
              different privacy policy.
            </div>
          </div>
        </div>
      </div>
      <p>
        D) Transfers, storage, and protection of information
      </p>
      <p>
        We operate and share information globally, both internally and externally (suppliers, consultants or service providers) in accordance with this Policy. Your information may therefore be transferred internationally, stored and processed in different countries in order for us to be able to provide you with the Services and access to the Payment Services.
      </p>
      <p>
        Koralgo and the Platform developers have designed comprehensive security features and process to ensure the safety and integrity of the data stored by the Platform.
        We will retain your personal data as long as is required for you to use the Platform, enable us to provide the Services and in accordance with legal and regulatory requirements.
      </p>
      <p>
        E) Cookies policy
      </p>
      <p>
        Full details can be found in our
        {' '}
        <a href='/cookies-policy' className="inlined-link">
          Cookies Policy
        </a>
        , including how to manage your cookies related settings. The cookies we
        use are small files stored on your computer or mobile device to enable
        us to provide some services and functionality (Strictly Necessary
        cookies), improve navigation and user experience (Performance cookies)
        and provide enhanced, more personal features (Functionality cookies).
      </p>
      <p>
        F) Changes to the policy
      </p>
      <p>
        You will be notified of any change to this Policy by email (at the address specified in your account) prior to the change becoming effective.
        If you continue to us the Services after those changes are in effect, you agree to the revised policy.
      </p>
      <p>
        G) Your rights
      </p>
      <p>
        In line with prevailing regulation, you have the following rights:
      </p>
      <div className="ml-15 mb-1em">
        <div className="mb-1em">
          <div>
            • Data access and portability
          </div>
          <div>
            You are entitled to know what personal data we have about you and the right to copy, transfer, or move this personal data to a different company.
          </div>
        </div>
        <div className="mb-1em">
          <div>
            • Rectification
          </div>
          <div>
            You have the right to have any inaccurate or incomplete personal data rectified
          </div>
        </div>
        <div className="mb-1em">
          <div>
            • Right to erasure:
          </div>
          <div>
            You essentially have the right to “be forgotten” by requesting us to delete your data and stop processing it.
            Note that we may retain specific data for security reasons (such as fraud prevention), legal or regulatory reasons as well as in our back-up systems for a limited time (depending on databases back up processes and cycles).
          </div>
        </div>
        <div className="mb-1em">
          <div>
            • Restrict or objection to processing
          </div>
          <div>
            You have the right to restrict personal data processing under certain circumstances or object to the processing of your data going forward (effectively withdrawing your consent).
          </div>
        </div>
        <div className="mb-1em">
          <div>
            • Right to be informed
          </div>
          <div>
            This is achieved by providing you with the information contained in this Privacy Policy, Cookies Policy and Terms of Service.
          </div>
        </div>
      </div>
      <p>
        H) How to access, modify or delete your personal information
      </p>
      <div className="ml-15 mb-1em">
        <ul className={styles.list}>
          <li>
            Retrieval and modification: you can access and update your personal information through your Account settings, once you are logged in on the platform
          </li>
          <li>
            Deletion: you can request for your personal information to be deleted at any time by contacting Koralgo administrators through the contact form. Once your request has been processed, your personal account will be closed and all related personal information deleted 90 days after the end date of your last live booking – this timeline is simply to cater for potential cases of disputes between users and sellers
          </li>
        </ul>
      </div>
      <p>
        I) Koralgo contact in relation with privacy policy
      </p>
      <p>
        For queries relating to privacy policy, please contact us through this
        {' '}
        <Link to={CONTACTS_LINK} className="inlined-link">
          form
        </Link>
        {' '}
        and select the following topic: “Privacy and Cookies policies”.
      </p>
    </div>
  </div>
);

export default PrivacyPolicy;
