import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import SearchInputAutocomplete from './SearchInputAutocomplete/SearchInputAutocomplete';
import searchService from '../../../services/search.service';
import { TOOLBAR_SEARCH_ENTITY_TYPES } from '../../../constans/search';
import IconActivity from '../../../assets/img/icons/ic-activity.svg';
import IconProperty from '../../../assets/img/icons/ic_home.svg';

import styles from './NavigationSearch.module.scss';

const schema = object().shape({
  selectedName: string().required(),
});

const service = searchService();

function NavigationSearch({
  classNames = '',
  isMobile = false,
}) {
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchSubmit = ({ selectedName }, { resetForm }) => {
    if (searchResults.length) {
      const { id, type } = { ...searchResults.find(({ title }) => title === selectedName) };

      if (id) {
        if (type === TOOLBAR_SEARCH_ENTITY_TYPES.activity) {
          window.open(`/activities?id=${id}`, '_blank', 'noopener noreferrer');
        } else {
          window.open(`/results-shared/?propertyId=${id}&detailsView=true`, '_black', 'noopener noreferrer');
        }
        resetForm();
        setSearchResults([]);
      }
    }
  };

  const handleSearch = async (q) => {
    const res = await service.getReviews(q, 20, 0);
    setSearchResults(res.data);
    return res;
  };

  return (
    <Formik
      onSubmit={handleSearchSubmit}
      validationSchema={schema}
      initialValues={{
        name: '',
        selectedName: '',
      }}
    >
      {({ setFieldValue, submitForm }) => (
        <Form className={`${styles.form} ${classNames}`}>
          <SearchInputAutocomplete
            isMobile={isMobile}
            submitForm={submitForm}
            inputInitialValue=""
            onChange={handleSearch}
            onSelect={(v) => setFieldValue('selectedName', v)}
            wrapperClassName={`position-relative mb-0 ${styles.activityFormInput}`}
            placeholder="Search reviews"
            onFocus={() => setFieldValue('selectedName', '')}
            getResultValue={(v) => v.title}
            setFieldValue={setFieldValue}
            dropdownStyles={styles.activityDropdown}
            dropdownItemTextStyles={styles.activityItem}
            name="activityName"
            prefixActivityIcon={<IconActivity className={styles.icon} />}
            prefixPropertyIcon={<IconProperty className={`${styles.icon} ${styles.iconProperty}`} />}
          />
        </Form>
      )}
    </Formik>
  );
}

export default NavigationSearch;
