import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Footer from '../Footer/Footer';
import Toolbar from '../Navigation/Toolbar/Toolbar';
import UnAuth from '../Navigation/UnAuth/UnAuth';
import SideDrawer from '../Navigation/SideDrawer/SideDrawer';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { useBodyBackDrop } from '../../hooks/useBodyBackDrop';
import { SIDEDRAWER_BREAKPOINT } from '../../constans/header';
import { BODY_BACKDROPED } from '../../constans/css-classes';
import { UNAUTH_NAV_DATA, CUSTOMER_NAVBAR, PARTNER_NAV_DATA } from '../../constans/links';
import StorageService from '../../services/storage.service';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import Roles from '../../constans/roles';
import Cookies from '../Cookies/Cookies';
import NavigationSearch from '../Navigation/NavigationSearch/NavigationSearch';
import NavigationSearchMobile from '../Navigation/NavigationSearch/NavigationSearchMobile/NavigationSearchMobile';
import StatusModal from '../StatusModal';

import './Layout.scss';

function Layout({
  children,
  mainClasses,
  noFooter,
  navigation,
  noTopNav,
  userName,
}) {
  // Depending on isAuth state set One or the other toolbar children
  const [sideDrawerIsOpen, setSideDrawerIsOpen] = useState(false);
  const [useNameData, setUseNameData] = useState('');
  const [isCustomer, setIsCustomer] = useState(false);
  const [topNavIsHidden, setTopNavIsHidden] = useState(noTopNav);
  const [userNav, setUserNav] = useState(navigation);
  const [isStatusShown, setIsStatusShown] = useState(false);
  const [userData, setUserData] = useState(StorageService().get(LOCALSTORAGE_KEYS.USER));

  const windowWidth = useWindowWidth();
  useBodyBackDrop(windowWidth >= SIDEDRAWER_BREAKPOINT);
  const isNotSeller = userData?.role !== Roles.SUPPLIER && userData?.role !== Roles.PARTNER;
  const isPartner = userData?.role === Roles.PARTNER;

  const sideDrawerToggleHandler = () => {
    document.body.classList.toggle(BODY_BACKDROPED);
    setSideDrawerIsOpen((isOpen) => !isOpen);
  };

  const sideDrawerCloseHandler = () => {
    document.body.classList.remove(BODY_BACKDROPED);
    setSideDrawerIsOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    const isCustomerRole = userData?.role === Roles.RETAIL_CUSTOMER;
    const isPartner = userData?.role === Roles.PARTNER;
    const isSupplier = userData?.role === Roles.SUPPLIER;

    if (isCustomerRole && !isPartner && !isSupplier) {
      setUseNameData(`${userData?.nickName || `${userData?.firstName} ${userData.lastName}` || ''}`);
      setUserNav(CUSTOMER_NAVBAR);
      setIsCustomer(isCustomerRole);
      setTopNavIsHidden(true);
    }
    /*
    * Assuming that userName and navigation
    * Can be passed through props only from pages/sellers/index.js (Seller routes)
    */
    if (userName && !isCustomerRole && !isPartner) {
      setUseNameData(userName);
      setIsCustomer(false);
      setUserNav(navigation);
    }

    if (isPartner && !isCustomerRole && !isSupplier) {
      setUseNameData(`${userData?.nickName || ''}`);
      setIsCustomer(false);
      setUserNav([
        ...navigation,
        ...PARTNER_NAV_DATA,
      ]);
    }

    if (!userName && !isCustomerRole && !isPartner && !isSupplier) {
      setUseNameData('');
      setIsCustomer(false);
    }
  }, [userData]);

  useEffect(() => StorageService().onChange(LOCALSTORAGE_KEYS.USER, setUserData), []);

  useEffect(() => {
    if (windowWidth >= SIDEDRAWER_BREAKPOINT) {
      setSideDrawerIsOpen(false);
    }
  }, [windowWidth]);

  return (
    <>
      <Toolbar isCustomer={isCustomer}>
        <div className="flex flex-grow-1 flex-v-center flex-between m-0">
          <div className="searchBlock">
            <NavigationSearch classNames="flex-shrink-1" />
          </div>
          <div className="m-0 row flex-shrink-0">
            <NavigationSearchMobile />
            <div className="menu-divider" />
            <UnAuth
              isNotSeller={isNotSeller}
              userName={useNameData}
              isCustomer={isCustomer}
              hideLinks={topNavIsHidden}
              toggleSideDrawer={sideDrawerToggleHandler}
              isPartner={isPartner}
            />
          </div>
        </div>
      </Toolbar>
      <SideDrawer
        isNotSeller={isNotSeller}
        onStatusClick={() => setIsStatusShown(true)}
        userName={useNameData}
        links={userNav}
        onLinkClick={sideDrawerToggleHandler}
        hide={sideDrawerCloseHandler}
        isOpen={sideDrawerIsOpen}
        isPartner={isPartner}
      />
      <main className={mainClasses}>{children}</main>
      <Cookies />
      <StatusModal isOpen={isStatusShown} toggle={() => setIsStatusShown((state) => !state)} />
      {!noFooter && <Footer />}
    </>
  );
}

Layout.propTypes = {
  mainClasses: PropTypes.string,
  noFooter: PropTypes.bool,
  noTopNav: PropTypes.bool,
  userName: PropTypes.string,
  // isCustomer: PropTypes.bool,
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
    }),
  ),
};

Layout.defaultProps = {
  noFooter: false,
  noTopNav: false,
  // isCustomer: false,
  mainClasses: '',
  navigation: UNAUTH_NAV_DATA,
  userName: '',
};

export default Layout;
