import moment from 'moment';

import http from './http.service';
import { defaultParamsSerializer, removeEmptyObjectKeys } from '../helpers/helper-methods';
import { ROOM_SEARCH_LIMIT } from '../constans/pagination';
import { LOYALTY_PROGRAM_KEY_NAMES } from '../constans/search';
import { SEARCH_REQUEST_FORMAT } from '../constans/formats';

const SearchService = () => {
  const basePath = '/search';

  const createSearchUUID = (model) => {
    const {
      adultGuests,
      kidGuests,
      kids,
      rooms,
      maximized,
      optimized,
      checkin,
      checkout,
      maxPrice,
      minPrice,
      priceType,
      currency,
      hotel,
      focus,
      // ambiance,
      propertyType,
      freeBreakfastInLounge,
      alcoholicDrinksInLounge,
      foodAndNonAlcoholicDrinks,
      loyaltyPrograms,
      requiredOptions,
      noRefund,
      confidentOnBooking,
      travelInsurance,
      userProfile,
      // flexPeriod,
      cityId,
      countryId,
      regionId,
      districtId,
    } = model;

    const processedPropertyTypes = propertyType
      .map((type) => (type.split(' ')))
      .flat();
    const propertyTypes = new Set(processedPropertyTypes);

    const propcessedRequiredOptions = {};

    // eslint-disable-next-line no-return-assign
    requiredOptions.forEach((value) => propcessedRequiredOptions[value] = true);

    const processedLoyaltyPrograms = Object.entries(loyaltyPrograms)
      .filter(([_programName, features]) => (features.length))
      .map((selectedProgram) => {
        const [programName, features] = selectedProgram;
        const reduced = features
          .map((feature) => ({ [feature]: true }))
          .reduce(((feature, value) => Object.assign(feature, value)), {});

        return {
          loyaltyProgram: LOYALTY_PROGRAM_KEY_NAMES[programName],
          ...reduced,
        };
      });

    const roomsOptions = removeEmptyObjectKeys({
      numberOfRooms: rooms,
      optimizeCapacity: optimized,
      maximumCapacity: maximized,
    });
    const selectedDates = removeEmptyObjectKeys({
      checkin: checkin ? moment(checkin).format(SEARCH_REQUEST_FORMAT) : null,
      checkout: checkout ? moment(checkout).format(SEARCH_REQUEST_FORMAT) : null,
      // flexibleDuration: flexPeriod ? parseInt(flexPeriod, 10) : null,
    });

    const otherFields = removeEmptyObjectKeys({
      cityId,
      countryId,
      regionId,
      districtId,
      hotelName: hotel,
      tripFocus: focus,
      // tripPreferredAtmosphere: ambiance,
      propertyTypes: Array.from(propertyTypes),
      loyaltyPrograms: processedLoyaltyPrograms,
      requiredOptions: propcessedRequiredOptions,
      executiveLounge: removeEmptyObjectKeys({
        freeBreakfastInLounge,
        alcoholicDrinksInLounge,
        foodAndNonAlcoholicDrinks,
      }),
    });

    if (noRefund === true) {
      const cancellation = {};

      cancellation.travelInsurance = travelInsurance;
      cancellation.confidentOnBooking = confidentOnBooking;

      otherFields.cancellation = cancellation;
    }

    const capacityAdults = Math.ceil(adultGuests / roomsOptions.numberOfRooms);
    const capacityKids = Math.ceil(kidGuests / roomsOptions.numberOfRooms);

    const guests = removeEmptyObjectKeys({
      adults: capacityAdults,
      kids: capacityKids,
      ageOfKids: kids.length ? kids : '',
    });

    const requestModel = {
      guests,
      rooms: roomsOptions,
      selectedDates,
      budget: {
        currency,
        priceType,
        min: Math.ceil(minPrice / roomsOptions.numberOfRooms),
        max: Math.ceil(maxPrice / roomsOptions.numberOfRooms),
      },
      // ! following fields are optional
      ...otherFields,
      userProfile,
    };

    return http.post(`${basePath}/properties/search`, requestModel);
  };

  const getPropertiesByUUID = (model) => http.get(`${basePath}/properties/search`, {
    params: model,
    paramsSerializer: (params) => defaultParamsSerializer(params),
  });

  const getPropertyOffersByUUID = (model) => http.get(`${basePath}/offers`, {
    params: model,
    paramsSerializer: (params) => defaultParamsSerializer(params),
  });

  const getPropertyRoomsByUUID = (propertyId, requestId, page) => (
    http.get(`${basePath}/rooms`, {
      params: {
        propertyId,
        uuid: requestId,
        limit: ROOM_SEARCH_LIMIT,
        offset: page * ROOM_SEARCH_LIMIT,
      },
    })
  );

  const getBestOffersByUUID = (uuid) => (
    http.get(`${basePath}/offers/best`, {
      params: {
        uuid,
      },
    })
  );

  const getReviews = (q, limit, offset) => (
    http.get(`${basePath}/reviews`, {
      params: {
        q: q.replace(/'/g, "\\'"),
        limit,
        offset,
      },
    })
  );

  return {
    createSearchUUID,
    getPropertiesByUUID,
    getPropertyOffersByUUID,
    getPropertyRoomsByUUID,
    getBestOffersByUUID,
    getReviews,
  };
};

export default SearchService;
