import React, { useEffect, useMemo } from 'react';
import { Link } from 'gatsby';
import propTypes from 'prop-types';

import LogoSvg from '../../assets/img/logo_vector.svg';
import classes from './Logo.module.scss';
import StorageService from '../../services/storage.service';
import SessionService from '../../services/session.service';
import CookiesService from '../../services/cookie.service';
import { COOKIES_KEYS, LOCALSTORAGE_KEYS, SESSIONSTORAGE_KEY } from '../../constans/localstorage';
import ROLES from '../../constans/roles';
import { API } from '../../constans/http';
import http from '../../services/http.service';
import { SEARCH_LINK } from '../../constans/links';

function Logo({ containerClasses }) {
  const storeService = useMemo(() => StorageService(), []);
  const sessionService = useMemo(() => SessionService(), []);
  const cookiesService = useMemo(() => CookiesService(), []);
  const { role = '' } = storeService.get(LOCALSTORAGE_KEYS.USER);
  let logoPartner = sessionService.get(SESSIONSTORAGE_KEY.PARTNER_LOGO, '');
  let logoSubtitle = sessionService.get(SESSIONSTORAGE_KEY.LOGO_SUBTITLE, '');

  useEffect(() => {
    if (!logoPartner) {
      const partnerNickname = cookiesService.getCookie(COOKIES_KEYS.PARTNER_NICKNAME);
      if (partnerNickname) {
        http.get(`${API.PARTNERS}/${partnerNickname}/logo`, { useToast: false })
          .then(({ data }) => {
            const { file } = data;
            if (file.link) {
              SessionService().set(SESSIONSTORAGE_KEY.PARTNER_LOGO, file.link);
              SessionService().set(SESSIONSTORAGE_KEY.LOGO_SUBTITLE, data.subTitle);
              logoPartner = file.link;
              logoSubtitle = data.subTitle;
              window.location.reload();
            }
          });
      }
    }
  }, []);

  return (
    <Link to={role === ROLES.SUPPLIER ? '/sellers' : SEARCH_LINK} className={`${classes.logo} ${containerClasses} flex flex-v-center`}>
      {logoPartner
        && (
        <>
          <div><img src={logoPartner} alt="partner-logo" /></div>
          {logoSubtitle && <span className={`${classes.subTitle}`}>{logoSubtitle}</span>}
          <div className={`${classes.separator}`}>&</div>
        </>
        )}
      <div><img src={LogoSvg} alt="logo" /></div>
    </Link>
  );
}

Logo.propTypes = {
  containerClasses: propTypes.string,
};

Logo.defaultProps = {
  containerClasses: '',
};

export default Logo;
