import { useEffect, useState, useCallback } from 'react';

const useResize = (callback) => {
  useEffect(() => {
    window.addEventListener('resize', callback);
    callback();
    return () => window.removeEventListener('resize', callback);
  }, [callback]);
};

export const useWindowWidth = () => {
  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(() => {
    setWidth(document.documentElement.clientWidth);
  }, []);

  useResize(updateWidth);

  return width;
};
