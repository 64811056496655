import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';

import classes from './NavigationItems.module.scss';
import NavigationItem from './NavigationItem/NavigationItem';
import StatusModal from '../../StatusModal';
import FileSaverService, {
  DOCUMENT_TYPES,
} from '../../../services/file-saver.service';
import SessionService from '../../../services/session.service';
import { SESSIONSTORAGE_KEY } from '../../../constans/localstorage';

const DESTINATION_LIST = 'Destinations';
const USER_GUIDE = 'User Guide';
const defaultState = {
  link: '',
};

function navigationItems({ linksArr, containerClasses, onLinkClick }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isStatusShown, setIsStatusShown] = useState(false);
  const [destinationList, setDestinationList] = useState(
    SessionService().get(SESSIONSTORAGE_KEY.DESTINATION_LIST, defaultState)
  );

  const getDestinationList = useCallback(() => {
    FileSaverService()
      .getDocumentByDocumentType(DOCUMENT_TYPES.destinationList)
      .then(({ data }) => {
        setDestinationList(data);
        SessionService().set(SESSIONSTORAGE_KEY.DESTINATION_LIST, data);
      });
  }, []);

  useEffect(() => {
    if (!destinationList.link) getDestinationList();
  }, [destinationList]);

  return (
    <ul className={`${classes.NavigationItems} ${containerClasses}`}>
      {linksArr?.map((linkItem, index) => (
        <NavigationItem
          onLinkClick={onLinkClick}
          link={linkItem.href}
          key={index}
        >
          {linkItem.title}
        </NavigationItem>
      ))}
      {destinationList?.link?.length && (
        <NavigationItem
          onLinkClick={onLinkClick}
          link={destinationList.link}
          key={DESTINATION_LIST}
          isExternal
        >
          {DESTINATION_LIST}
        </NavigationItem>
      )}
      <NavigationItem
        onLinkClick={onLinkClick}
        link="/userGuide"
        key={USER_GUIDE}
      >
        {USER_GUIDE}
      </NavigationItem>
      <StatusModal
        isOpen={isStatusShown}
        toggle={() => setIsStatusShown((state) => !state)}
      />
    </ul>
  );
}
navigationItems.propTypes = {
  containerClasses: propTypes.string,
  onLinkClick: propTypes.func,
};

navigationItems.defaultProps = {
  containerClasses: '',
  onLinkClick: () => {},
};

export default navigationItems;
