import React, { useState } from 'react';

import IconSearch from '../../../../assets/img/icons/ic-search.svg';
import Modal from '../../../UI/Modal/Modal';
import NavigationSearch from '../NavigationSearch';

import styles from './NavigationSearchMobile.module.scss';

const NavigationSearchMobile = ({
  classNamesButton = '',
  classNamesIcon = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prevState) => !prevState);

  return (
    <>
      <Modal
        modalClasses={`infoModal infoTextWrapper ${styles.modal}`}
        displayToggle={toggle}
        show={isOpen}
        showClose={toggle}
      >
        <div className={styles.contentWrapper}>
          <NavigationSearch isMobile />
        </div>
      </Modal>
      <div className={`${styles.button} ${classNamesButton}`} onClick={toggle}>
        <IconSearch className={`${classNamesIcon}`} />
      </div>
    </>
  );
};

export default NavigationSearchMobile;
