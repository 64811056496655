import React, { useState } from 'react';

import { useLockBodyScroll } from '../../../hooks/useBodyScrollLock';
import Logo from '../../Logo/Logo';
import IconMenu from '../../../assets/img/icons/ic-menu.svg';
import ClientMenu from '../ClientMenu/ClientMenu';

import classes from './Toolbar.module.scss';

const Toolbar = ({ isCustomer, children }) => {
  const [isMenuShown, setIsMenuShown] = useState(false);
  const { enable, disable } = useLockBodyScroll();

  const handleOpen = () => {
    disable();
    setIsMenuShown(true);
  };

  const handleClose = () => {
    enable();
    setIsMenuShown(false);
  };

  return (
    <header className={`${classes.toolbar}`}>
      <div
        className={`${classes.toolbarContainer} flex flex-v-center`}
      >
        <div className="flex">
          {isCustomer && (
            <button
              onClick={handleOpen}
              className={`btn ${classes.button}`}
              type="button"
            >
              <IconMenu />
            </button>
          )}
          <Logo imgSrc={Logo} containerClasses={classes.logo} />
        </div>
        <ClientMenu
          onClose={handleClose}
          isShown={isMenuShown}
        />
        {children}
      </div>
    </header>
  );
};

export default Toolbar;
