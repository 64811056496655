import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'gatsby';

import { Input } from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import InputPassword from '../../components/UI/InputPassword/InputPassword';
import Label from '../../components/UI/Label/Label';
import InputWrapper from '../../components/UI/InputWrapper/InputWrapper';
import { passwordValidationSchema, emailValidationSchema } from '../../constans/validation-schemas';
import { AUTH } from '../../constans/form-fields';
import {
  PASSWORD_INVALID_TIP,
  PASSWORD_VALID,
} from '../../constans/error-messages';

import classes from './index.module.scss';

const initialValues = {
  email: AUTH.email.initialValue,
  password: AUTH.password.initialValue,
};

const validationSchema = Yup.object({
  password: passwordValidationSchema,
}).concat(emailValidationSchema);

const SignInForm = ({ formSubmitHandler, forgotLink }) => (
  <Formik
    validateOnMount
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={formSubmitHandler}
  >
    {({ isValid, errors }) => (
      <Form className={`${classes.form} flex flex-col flex-v-end`}>
        <InputWrapper>
          <Label>Email</Label>
          <Input
            type="email"
            name={AUTH.email.name}
            placeholder={AUTH.email.placeHolder}
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Password</Label>
          <div>
            <InputPassword
              hideError
              name={AUTH.password.name}
              placeholder={AUTH.password.placeHolder}
            />
            <div className="password-tip">
              {errors.password ? (
                PASSWORD_INVALID_TIP
              ) : (
                <span className="success-color">
                  {PASSWORD_VALID}
                </span>
              )}
            </div>
          </div>
        </InputWrapper>
        {forgotLink || (
          <Link className={classes.forgotBlock} to="/forgot-password">
            Forgot password
          </Link>
        )}
        <Button
          disabled={!isValid}
          classes=" btn btn_block btn-uppercased btn_common btn_auth t-600"
          type="submit"
        >
          Sign In
        </Button>
      </Form>
    )}
  </Formik>
);

export default SignInForm;
