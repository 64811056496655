import React, { useEffect, useMemo, useState } from 'react';

import ShieldIcon from '../../assets/img/icons/ic-shield.svg';
import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';
import CookiesPolicy from '../Landing-info/CookiesPolicy/CookiesPolicy';
import classes from './Cookies.module.scss';
import { BODY_BACKDROPED } from '../../constans/css-classes';
import StorageService from '../../services/storage.service';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';

const Cookies = () => {
  const storage = useMemo(() => StorageService(), []);
  const [hideBlock, setHideBlock] = useState(true);
  const [showCookies, setShowCookies] = useState(false);

  const toggleBodyBackDrop = () => document.body.classList.toggle(BODY_BACKDROPED);

  const toggleCookiesModal = () => {
    toggleBodyBackDrop();
    setShowCookies(!showCookies);
  };

  const hideBlockHandler = () => {
    setHideBlock(!hideBlock);
    storage.set(LOCALSTORAGE_KEYS.COOKIES, !hideBlock);
  };

  useEffect(() => {
    const isBannerHidden = storage.get(LOCALSTORAGE_KEYS.COOKIES, false);

    setHideBlock(isBannerHidden);
  }, []);

  return (
    <>
      <Modal
        showClose={false}
        modalClasses="infoModal"
        displayToggle={toggleCookiesModal}
        show={showCookies}
      >
        <CookiesPolicy
          show={showCookies}
          onSubmit={() => {
            toggleCookiesModal();
            setHideBlock(true);
          }}
        />
      </Modal>
      <div className={`${classes.cookies} ${hideBlock ? classes.hided : ''}`}>
        <div
          className={`${classes.container} landing-container flex flex-v-center flex-between`}
        >
          <div className={`${classes.textual} flex flex-h-start`}>
            <ShieldIcon className={classes.shieldIcon} />
            <p className={classes.text}>
              We use cookies on this site to enhance your user experience. For a
              complete overview of all cookies used, please see
              {' '}
              <span
                onClick={toggleCookiesModal}
                className={`${classes.moreBtn} t-700`}
              >
                MORE INFORMATION
              </span>
              .
            </p>
          </div>
          <Button
            onClick={hideBlockHandler}
            classes={`${classes.acceptBtn} btn btn-uppercased btn_common t-600`}
          >
            accept all
          </Button>
        </div>
      </div>
    </>
  );
};

export default Cookies;
