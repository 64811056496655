import React, { useState } from 'react';
import { Link } from 'gatsby';

import { BODY_BACKDROPED } from '../../../constans/css-classes';
import Modal from '../../UI/Modal/Modal';
import TemsOfService from '../TemsOfService/TemsOfService';
import CookiesPolicy from '../CookiesPolicy/CookiesPolicy';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import { USER_INFO_PAGES } from '../../../constans/links';

import classes from './LandingInfoWrapper.module.scss';

function LandingInfoWrapper({
  btnsWrapper,
}) {
  const [showTerms, setShowTerms] = useState(false);
  const [showCookies, setShowCookies] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const showInnerTermsModal = () => {
    setShowCookies(false);
    setShowPrivacy(false);
    setShowTerms(true);
  };

  const showInnerCookiesModal = () => {
    setShowCookies(true);
    setShowPrivacy(false);
    setShowTerms(false);
  };

  const toggleBodyBackDrop = () => document.body.classList.toggle(BODY_BACKDROPED);

  const toggleTermsModal = () => {
    toggleBodyBackDrop();
    setShowTerms(!showTerms);
  };
  const toggleCookiesModal = () => {
    toggleBodyBackDrop();
    setShowCookies(!showCookies);
  };
  const togglePrivacyModal = () => {
    toggleBodyBackDrop();
    setShowPrivacy(!showPrivacy);
  };

  return (
    <>
      <Modal
        modalClasses="infoModal infoTextWrapper"
        displayToggle={toggleTermsModal}
        show={showTerms}
      >
        <TemsOfService />
      </Modal>
      <Modal
        modalClasses="infoModal infoTextWrapper"
        displayToggle={toggleCookiesModal}
        show={showCookies}
        showClose={false}
      >
        <CookiesPolicy showClose={false} onSubmit={toggleCookiesModal} show={showCookies} />
      </Modal>
      <Modal
        modalClasses="infoModal infoTextWrapper"
        displayToggle={togglePrivacyModal}
        show={showPrivacy}
      >
        <PrivacyPolicy
          showInnerCookiesModal={showInnerCookiesModal}
          showInnerTermsModal={showInnerTermsModal}
        />
      </Modal>
      <div className={`${btnsWrapper} flex flex-v-center`}>
        {USER_INFO_PAGES.map((linkItem, index) => (
          <Link
            key={index}
            partiallyActive
            onClick={() => {}}
            to={linkItem.href}
            className={`${classes.link} ${index !== USER_INFO_PAGES.length - 1 ? classes.linkMargin : ''}`}
          >
            {linkItem.title}
          </Link>
        ))}
      </div>
    </>
  );
}

LandingInfoWrapper.defaultProps = {
  btnsWrapper: '',
  btn: '',
};

export default LandingInfoWrapper;
