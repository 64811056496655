import React from 'react';
import { navigate } from 'gatsby';

import http from '../../../services/http.service';
import classes from './SideDrawer.module.scss';
import Close from '../../../assets/img/icons/close.svg';
import NavigationItems from '../NavigationItems/NavigationItems';
import { UNAUTH_NAV_DATA } from '../../../constans/links';
import { API } from '../../../constans/http';
import SignNav from '../SignNav/SignNav';
import StorageService from '../../../services/storage.service';
import { BODY_BACKDROPED } from '../../../constans/css-classes';

function SideDrawer({
  isOpen, hide, links, userName, onLinkClick, isNotSeller, isPartner = false,
}) {
  const handleSignout = () => {
    http.delete(API.USER_LOGIN).then(() => {
      StorageService().clear();
      // navigate('/signin');
      document.body.classList.remove(BODY_BACKDROPED);
    });
  };

  return (
    <div
      className={`
        ${classes.sideDrawer} ${isOpen ? `${classes.active}` : ''}
        flex flex-col flex-v-center
      `}
    >
      <Close onClick={hide} className={classes.closeBtn} />
      <NavigationItems
        isNotSeller={isNotSeller}
        onLinkClick={onLinkClick}
        linksArr={isPartner ? '' : (links || UNAUTH_NAV_DATA)}
      />
      {userName ? (
        <>
          <p className={`${classes.userName} text-truncate`}>{userName}</p>
          <button
            type="button"
            className={classes.logout}
            onClick={handleSignout}
          >
            log out
          </button>
        </>
      ) : (
        <SignNav containerClasses={classes.signBlock} />
      )}
    </div>
  );
}

export default SideDrawer;
