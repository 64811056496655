import React, { useState } from 'react';

import { navigate } from 'gatsby';
import StorageService from '../../services/storage.service';
import http from '../../services/http.service';
import Modal from '../UI/Modal/Modal';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import { API, HTTP_STATUS } from '../../constans/http';

import styles from './SignInModal.module.scss';
import SignUpForm from '../../pages/signup/form';
import SignInForm from '../../pages/signin/form';
import {
  BLOCKED_USER_LINK,
  MY_PROPERTIES_LINK,
  SEARCH_LINK,
  VERIFICATION_LINK,
} from '../../constans/links';
import Roles from '../../constans/roles';

const DESCRIPTION_TEXT = [
  'Sign up to save your profile and be informed of new features and destinations.',
  'Please sign up so we can send the document to a verified email address. We’ll also keep you informed of new features and destinations.',
];

function SignUpModal({
  isShown,
  toggleModal,
  title,
  subTitle,
  isSignIn = true,
  withOutRedirect = true,
  paymentFunction,
}) {
  const [loginValues, setLoginValues] = useState({});
  const [isSignUp, setIsSignUp] = useState(false);
  const [isSignInFirst, setIsSignInFirst] = useState(isSignIn);
  const changeModalWindow = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'onClickSignUp',
    });
    setIsSignInFirst(!isSignInFirst);
  };

  const signInformSubmitHandler = (values) => {
    http
      .post(API.USER_LOGIN, { ...values })
      .then(({ data: { session, user } }) => {
        const client = user;
        const { searchProfile } = client;

        if (searchProfile) {
          const {
            // eslint-disable-next-line no-unused-vars
            id,
            createdAt,
            updatedAt,
            ...restOfProfile
          } = searchProfile;

          client.searchProfile = restOfProfile;
        }

        StorageService().set(LOCALSTORAGE_KEYS.SESSION, session);
        StorageService().set(LOCALSTORAGE_KEYS.USER, client);

        // eslint-disable-next-line max-len
        if (
          user?.role === Roles.SUPPLIER &&
          user?.role !== Roles.PARTNER &&
          user?.role !== Roles.RETAIL_CUSTOMER
        ) {
          navigate(MY_PROPERTIES_LINK);
          toggleModal();
          // eslint-disable-next-line max-len
        } else if (
          user?.role === Roles.RETAIL_CUSTOMER &&
          user?.role !== Roles.PARTNER &&
          user?.role !== Roles.SUPPLIER
        ) {
          if (user?.searchProfile) {
            //  const currentPathname = window.location.pathname;
            if (withOutRedirect) {
              navigate(SEARCH_LINK);
            }
            toggleModal();
          } else {
            //    navigate('/customers/complete-profile/');
            toggleModal();
            if (withOutRedirect) {
              navigate(SEARCH_LINK);
            }
          }
        } else if (user?.role === Roles.PARTNER) {
          navigate(`/?BP=${user?.nickName}`);
          toggleModal();
        } else toggleModal();
      })
      .catch(({ status }) => {
        if ([HTTP_STATUS.BLOCKED_USER].includes(status)) {
          navigate(BLOCKED_USER_LINK);
        } else if ([HTTP_STATUS.UNVERIFIED].includes(status)) {
          navigate(VERIFICATION_LINK, { state: { pwdRedirect: true } });
        }
      });
  };

  const signUpFormSubmitHandler = (values) => {
    http.post(API.USER_REG, { ...values }).then(({ data }) => {
      StorageService().set(LOCALSTORAGE_KEYS.REGUSER, data);
      //       navigate('/email-notif');
      setLoginValues({
        email: values.email,
        password: values.password,
      });
      toggleModal();
      setIsSignUp(true);
    });
  };

  const startLogin = () => {
    setIsSignUp(!isSignUp);
    signInformSubmitHandler(loginValues);
    paymentFunction();
  };

  return (
    <>
      <Modal
        show={isShown}
        displayToggle={toggleModal}
        modalClasses={styles.modal}
      >
        {!isSignInFirst ? (
          <>
            {!subTitle && (
              <h5 className={styles.title}>{title || 'Sign Up'}</h5>
            )}
            {!subTitle && (
              <h5 className={styles.description}>{DESCRIPTION_TEXT[0]}</h5>
            )}
            {subTitle && (
              <h5
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />
            )}

            <SignUpForm formSubmitHandler={signUpFormSubmitHandler} />
            <div className={`${styles.signInWrapper} t-600`}>
              <span>Already have an account?</span>{' '}
              <div onClick={changeModalWindow}>Sign In</div>
            </div>
          </>
        ) : (
          <>
            <h5 className={styles.title}>{title || 'Sign In'}</h5>
            <SignInForm
              formSubmitHandler={signInformSubmitHandler}
              forgotLink={
                <a
                  target="_blank"
                  rel="norefer noopener"
                  className={styles.forgotBlock}
                  href="/forgot-password"
                >
                  Forgot password
                </a>
              }
            />
            <div className={`${styles.signInWrapper} t-600`}>
              <span>Don&apos;t have an account?</span>{' '}
              <div onClick={changeModalWindow}>Sign Up</div>
            </div>
          </>
        )}
      </Modal>
      <Modal
        show={isSignUp}
        displayToggle={startLogin}
        modalClasses={styles.modal}
      >
        <h5 className={styles.title}>Verification</h5>
        <p>
          We have sent you an email to validate your address and complete the
          sign-up process
        </p>
      </Modal>
    </>
  );
}

SignUpModal.defaultProps = {
  toggleModal: null,
};

export default SignUpModal;
