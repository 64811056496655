import { saveAs } from 'file-saver';
import http from './http.service';

export const DOCUMENT_TYPES = {
  destinationList: 'DESTINATION_LIST',
  userGuide: 'USER_GUIDE',
};

const FileSaverService = () => {
  const basePath = '/files';
  const saveFile = (source, fileName) => {
    saveAs(source, fileName);
  };

  const getDocumentByDocumentType = (documentType) => http.get(`${basePath}`, { config: {useToast: false }, params: { documentType} });

  return { saveFile, getDocumentByDocumentType };
};

export default FileSaverService;
