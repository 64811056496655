import React from 'react';

import Modal from '../UI/Modal/Modal';

import styles from './index.module.scss';

const listItems = [
  'France: Paris, Bordeaux, Marseille, Nice, Aquitaine (R), Ile de France (R), Provence Alpes Côte d\'Azur(R)',
  'Spain: Barcelona, Madrid, Catalunya (R), Madrid & Castile-La Mancha (R)',
  'UK: London, London Region (R)',
  'Germany: Berlin, Munich, Berlin & Brandenburg (R)',
  'Netherlands: Amsterdam',
  'UAE: Dubai, Abu Dhabi',
  'Singapore',
  'Hong Kong',
];

const activityCount = 700;
const regionCount = 21;

const StatusModal = ({ isOpen, toggle }) => (
  <Modal
    displayToggle={toggle}
    show={isOpen}
    modalClasses={styles.statusModal}
  >
    <div>
      <p className="t-500">
        {`We have rated approx. ${activityCount} Activities in ${regionCount} Cities & Regions:`}
      </p>
      <ul className={styles.list}>
        {listItems.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
      <p className="t-500">
        20 demo hotels with reviews and offers are also available in London (10)
        and Hong Kong (10) to familiarize users with the personalized valuation and ranking concept
      </p>
    </div>
  </Modal>
);

export default StatusModal;
