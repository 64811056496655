/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Formik, Form } from 'formik';

import Checkbox from '../../UI/Checkbox/Checkbox';
import Button from '../../UI/Button/Button';
import StorageService from '../../../services/storage.service';
import { LOCALSTORAGE_KEYS } from '../../../constans/localstorage';
import { CONTACTS_LINK } from '../../../constans/links';

import classes from './CookiesPolicy.module.scss';

const initialValues = {
  strictly: true,
  performance: true,
  functionality: true,
};

const CookiesPolicy = ({ show, onSubmit }) => {
  const [initialFormValues, setInitialFormValues] = useState(initialValues);

  const handleFormSubmit = (values) => {
    StorageService()
      .set(LOCALSTORAGE_KEYS.COOKIE_VALUES, values);

    StorageService().set(LOCALSTORAGE_KEYS.COOKIES, true);
    onSubmit();
  };

  const handleSelectAll = (setValues) => {
    setValues(initialValues);
    StorageService().set(LOCALSTORAGE_KEYS.COOKIE_VALUES, initialValues);
    StorageService().set(LOCALSTORAGE_KEYS.COOKIES, true);

    onSubmit();
  };

  useEffect(() => {
    setInitialFormValues({
      ...initialValues,
      ...StorageService().get(LOCALSTORAGE_KEYS.COOKIE_VALUES),
    });
  }, [show]);

  return (
    <div className="infoTextContainer">
      <div className="infoTextTitle t-700">Cookies Policy</div>
      <div className="infoTextInner">
        <p>
          A) Definition
        </p>
        <p>
          In the context of internet and web browsers, a cookie is a small piece
          of data sent from a website or an application and stored on the user’s
          computer or mobile device. It is primarily designed to remember
          stateful information (such as items added in the shopping cart in an
          online store), to record the user’s browsing activity or to remember
          arbitrary pieces of information that the user previously entered into
          form fields such as names, addresses, passwords and credit card
          numbers.
        </p>
        <p>Source: based on Wikipedia entry for “HTTP Cookie”</p>
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          onSubmit={handleFormSubmit}
        >
          {({ setValues }) => (
            <Form>
              <p>B) Cookies’ types and usage</p>
              <Checkbox
                containerClasses={classes.checkboxContainer}
                checkboxClasses="c-custom__check-disabled c-custom__check-sm"
                labelClasses={`${classes.checkboxLabel} flex flex-v-center`}
                name="strictly"
              >
                Strictly necessary
              </Checkbox>
              <p>
                These cookies are essential to enable you to browse around the
                website/application and use its features. Without them, services
                you have asked for, like shopping baskets or e-billing, cannot
                be provided.
              </p>
              <Checkbox
                checkboxClasses="c-custom__check-bordered-primary c-custom__check-sm"
                containerClasses={classes.checkboxContainer}
                labelClasses={`${classes.checkboxLabel} flex flex-v-center`}
                name="performance"
              >
                Performance
              </Checkbox>
              <p>
                These cookies collect information about how you use our website
                / application in order to improve the user experience. They do
                not collect information that identifies you: all information
                they collect is aggregated and therefore anonymous.
              </p>
              <Checkbox
                checkboxClasses="c-custom__check-bordered-primary c-custom__check-sm"
                containerClasses={classes.checkboxContainer}
                labelClasses={`${classes.checkboxLabel} flex flex-v-center`}
                name="functionality"
              >
                Functionality
              </Checkbox>
              <p>
                These cookies allow the website / application to remember
                choices you make (such as your username, language, text size and
                font or the region you are in) and provide enhanced, more
                personal features. The information collected will not personally
                identify you and they cannot track your browsing activity on
                other websites.
              </p>
              <div
                className={`${classes.btnsWrapper} flex flex-wrap flex-v-center flex-center`}
              >
                <Button
                  type="submit"
                  classes={`${classes.formBtn} btn btn-uppercased btn_block btn_common t-600`}
                >
                  Confirm my Choices
                </Button>
                <Button
                  type="button"
                  classes={`${classes.formBtn} btn btn-uppercased btn_block btn_common t-600`}
                  onClick={() => handleSelectAll(setValues)}
                >
                  Accept all
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <p>
          Note that our advertising services are actively opted-in and managed
          by signed-up users from their settings menu so that we do not make use
          of targeting and advertising cookies.
        </p>
        <p>
          We also use other technologies that provide us with similar type of
          functionality such as URLs tracking and web beacons in order to obtain
          Log Data (as defined in our Privacy Policy)
        </p>
        <p>
          We use Cookies and related technologies to provide the following
          functionalities and services:
        </p>
        <div className="ml-15 mb-1em">
          <div>• Authentication</div>
          <div>• Access and usage of the Koralgo platform and the Payment Services</div>
          <div>• Security, investigations, site and product integrity</div>
          <div>• Improved profiling, insights and measurements</div>
          <div>• Platform navigation and enhancement of user experience</div>
          <div>• Legal and regulatory requirements</div>
          <div>• Monitor the operations and performance of the Platform</div>
          <div>• Research, product development, analytics and customer support</div>
        </div>
        <p>
          C) Managing cookies settings
        </p>
        <div className="ml-15 mb-1em">
          <div>
            • Acceptance of the cookie policy will be requested from you during you first visit through a “pop-up” and “I Accept” button
          </div>
          <div>
            • You will be provided with the option to adjust the setting and select the “Performance” or “Functionality” option, or both.
          </div>
          <div className="ml-15">
            ◦ This option remains available by amending the Cookie setting for registered users when logged in.
          </div>
          <div>
            • You will also be provided with the option to Reject the Cookie Policy, however this means that “Strictly Necessary” cookies will be disabled and our platform will therefore not be able to carry out some of its services such as bookings and payments.
          </div>
          <div className="ml-15">
            ◦ Some functions such as searches and reading Seller related information (e.g. offers description or reviews) are likely to be possible but functionalities will be significantly limited.
            Note that your browser or device may also permit you to change your cookies settings. Disabling cookies use through this method may impact the ability of Koralgo to provide some of its services and the website / application may not work properly.
          </div>
        </div>
        <p>
          D) Koralgo contact in relation with cookies policy
        </p>
        <p>
          For queries relating to cookies policy, please contact us through this
          {' '}
          <Link to={CONTACTS_LINK} className="inlined-link">
            form
          </Link>
          {' '}
          and select the following topic: “Terms, Privacy and Cookies”.
        </p>
      </div>
    </div>
  );
};

export default CookiesPolicy;
