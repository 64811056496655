import React from 'react';
import BackDrop from '../Backdrop/Backdrop';
import Close from '../../../assets/img/icons/close.svg';

import classes from './Modal.module.scss';

const Modal = ({
  children, show, displayToggle, modalClasses, showClose, modalWrapperStyles, closeStyles,
}) => (
  <>
    <BackDrop show={show} />
    <div className={`${classes.modalWrapper} ${modalWrapperStyles} ${show ? classes.active : ''}`}>
      <div className={`${classes.modal} ${modalClasses} container-bordered`}>
        {showClose ? (
          <Close onClick={displayToggle} className={`${classes.close} ${closeStyles}`} />
        ) : null}
        {children}
      </div>
    </div>
  </>
);

Modal.defaultProps = {
  showClose: true,
  modalWrapperStyles: '',
  closeStyles: '',
};

export default React.memo(Modal);
